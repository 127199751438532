<script lang="ts">
  import Button from './Button.svelte';
  import { twMerge } from 'tailwind-merge';
  export let provider: 'Google' | 'Facebook' | 'Clever';
  export let registration = false;
  export let text: string | null = null;

  $: inOrUp = registration ? 'up' : 'in';

  let clazz: string = '';
  let bgClass = 'bg-google-blue';
  export { clazz as class };

  $: {
    switch (provider) {
      case 'Clever':
        bgClass = 'bg-clever-blue';
        break;
      case 'Facebook':
        bgClass = 'bg-facebook-blue';
        break;
      case 'Google':
        bgClass = 'bg-google-blue';
        break;
    }
  }
</script>

<Button class={twMerge(`${bgClass} text-white ${clazz}`)} color="none" on:click>
  <span class="flex h-10">
    {#if provider === 'Google'}
      <img
        class="bg-white p-1 rounded-sm w-12 mx-auto"
        src="/img/components/google-logo.svg"
        alt="Google Logo"
      />
    {:else if provider === 'Clever'}
      <img
        class="bg-clever-blue border-r-2 border-clever-blue rounded-sm pr-2 w-12 h-10 mx-auto"
        src="/img/components/clever-logo-c-only-7.png"
        alt="Clever Logo"
      />
    {:else if provider === 'Facebook'}
      <img
        class="bg-white px-1 pb-0 pt-1.5 rounded-sm w-12 mx-auto"
        src="/img/components/facebook-logo.svg"
        alt="Facebook Logo"
      />
    {/if}
    <span class="pl-2 text-sm font-normal flex-grow self-center">
      {#if text}
        {text}
      {:else}
        Sign {inOrUp} with {provider}
      {/if}
    </span>
  </span>
</Button>

<style lang="postcss">
  :global(.bg-google-blue) {
    background-color: #4285f4;
  }
  :global(.bg-facebook-blue) {
    background-color: #3f5daf;
  }
  :global(.bg-clever-blue) {
    background-color: #436cf2;
  }
  :global(.border-clever-blue) {
    border-color: #8ca4f6;
  }
</style>
